// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #fbfafc;
  color: #000405;

  font-family: Rubik, Helvetica, sans-serif;

  font-size: 15px;
  font-weight: normal;

  line-height: 1em;
  margin: 0;
}

.outer {
  padding: 10px;
}

.container {
  width: 80%;
  margin: auto;
  border: 5px #333333 solid;
  padding: 10px;
  border-radius: 25px;
}

.input {
  width: 1;
  color: #777;
  padding: 8;
  margin: 10;
  width: 200;
}

.myform {
  padding: 20px;
}

.myform input[type="text"],
.myform textarea {
  padding: 8px;
  width: 100%;
}

.column {
  padding: 20px;
  float: left;
  width: 50%;
}

.column_aut {
  padding: 20px;
  float: left;
  width: 33%;
}

.Token_message {
  display: inline;
}

.swap {
  padding: 10px;
}

.swap_button {
  float: right;
}

.markdown {
  width: 80%;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;;EAEd,yCAAyC;;EAEzC,eAAe;EACf,mBAAmB;;EAEnB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,QAAQ;EACR,WAAW;EACX,UAAU;EACV,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd","sourcesContent":["body {\n  background-color: #fbfafc;\n  color: #000405;\n\n  font-family: Rubik, Helvetica, sans-serif;\n\n  font-size: 15px;\n  font-weight: normal;\n\n  line-height: 1em;\n  margin: 0;\n}\n\n.outer {\n  padding: 10px;\n}\n\n.container {\n  width: 80%;\n  margin: auto;\n  border: 5px #333333 solid;\n  padding: 10px;\n  border-radius: 25px;\n}\n\n.input {\n  width: 1;\n  color: #777;\n  padding: 8;\n  margin: 10;\n  width: 200;\n}\n\n.myform {\n  padding: 20px;\n}\n\n.myform input[type=\"text\"],\n.myform textarea {\n  padding: 8px;\n  width: 100%;\n}\n\n.column {\n  padding: 20px;\n  float: left;\n  width: 50%;\n}\n\n.column_aut {\n  padding: 20px;\n  float: left;\n  width: 33%;\n}\n\n.Token_message {\n  display: inline;\n}\n\n.swap {\n  padding: 10px;\n}\n\n.swap_button {\n  float: right;\n}\n\n.markdown {\n  width: 80%;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
